import { combineReducers } from "redux";
import initialState from "./initialState";
import isSuperUserAuth from "./isAuthRequest";
import isSubmitting from "./isSubmittingRequest";
import superUserParams from "./userParamsReducer";
import superUserInfo from "./userInfoReducer";
import collapseMenu from "./collapseMenuRequest";
import setting from "./settingReducer";
import isData from "./isDataRequest";
import emailData from "./emailReducer";
import pageData from "./pageDataReducer";
import pagination from "./paginationReducers";
import networkRequest from "./networkRequest";
import isHead from "./isHeadRequest";
import isLoad from "./isLoadRequest";
import faqData from "./faqDataReducer";
import reducer from "./collapseReducer";
import pathName from "./isPathNameRequest";
import customerData from "./customerDataReducer";
import dashboard from "./dashboardReducer";
import userList from "./userListReducer";
import notificationList from "./notificationDataReducer";
import requestParams from "./requestParamsReducer";
import contactData from "./contactDataReducer";
import toaster from "./toasterReducer";
import dialogOpen from "./dialogOpenReducer";
import notificationCount from "./notificationCountReducer";
import preLoader from "./preLoaderRequest";
import categoryData from "./categoryDataReducer";
import categoryList from "./categoryListReducer";
import videoData from "./videoDataReducer";
import newsData from "./newsDataReducer";
import adminData from "./adminDataReducer";
import userSelectData from "./userSelectDataReducer";
import subscription from "./subscriptionDataReducer";
import courseData from "./courseDataReducer";
import quizData from "./quizDataReducer";
import quizTopicData from "./quizTopicDataReducer";
import topicListing from "./topicListingReducer";
import studyData from "./studyDataReducer";
import promoCodeData from "./promoCodeDataReducer";
import ticketData from "./ticketReducer";
import paymentData from "./paymentDataReducer";
import purchasedTotal from "./purchasedTotalReducer";

const rootReducer = combineReducers({
  isSuperUserAuth,
  isSubmitting,
  superUserParams,
  superUserInfo,
  collapseMenu,
  setting,
  isData,
  emailData,
  pagination,
  isFetching: networkRequest,
  isHead,
  pageData,
  isLoad,
  faqData,
  reducer,
  pathName,
  customerData,
  dashboard,
  userList,
  notificationList,
  requestParams,
  contactData,
  toaster,
  dialogOpen,
  notificationCount,
  preLoader,
  categoryData,
  categoryList,
  videoData,
  newsData,
  adminData,
  userSelectData,
  subscription,
  courseData,
  quizData,
  quizTopicData,
  topicListing,
  studyData,
  promoCodeData,
  ticketData,
  paymentData,
  purchasedTotal,
});

export default function combinedRootReducer(state, action) {
  return action.type === "LOG_OUT"
    ? rootReducer(initialState, action)
    : rootReducer(state, action);
}
