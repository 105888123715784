// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isSuperUserAuth: false,
  isSubmitting: false,
  superUserParams: {},
  superUserInfo: {},
  isForgot: false,
  isOpen: [], //for active default menu
  isTrigger: [], //for active default menu, set blank for horizontal
  isFullScreen: false, // static can't change
  collapseMenu: true,
  setting: {},
  emailData: [],
  requestParams: {},
  pageData: [],
  faqData: [],
  customerData: [],
  isData: true,
  pagination: {},
  isFetching: false,
  isLoad: true,
  status: false,
  isHead: [],
  pathName: [],
  dashboard: {},
  userList: [],
  contactData: [],
  notificationList: [],
  notificationCount: 0,
  toaster: {
    open: false,
    message: "test",
    severity: "success",
  },
  dialogOpen: false,
  preLoader: false,
  categoryData: [],
  categoryList: [],
  videoData: [],
  newsData: [],
  adminData: [],
  userSelectData: [],
  subscription: [],
  courseData: [],
  quizData: [],
  quizTopicData: [],
  topicListing: [],
  studyData: [],
  promoCodeData: [],
  ticketData: [],
  paymentData: [],
  purchasedTotal: 0,
};
