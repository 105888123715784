import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function topicListingReducer(
  state = initialState.topicListing,
  action
) {
  switch (action.type) {
    case types.LOADED_TOPIC_LIST_SUCCESS:
      return action.topicListing;
    default:
      return state;
  }
}
